<template>
  <div class="cert" v-loading="pageLoading">
    <el-dialog
      custom-class="orderDialog"
      title=""
      :visible.sync="orderShow"
      width="409px"
      @close="orderShow = false"
    >
      <div class="orderShow">
        <van-image
          class="cover"
          width="100%"
          fit="cover"
          :error-icon="$placeImg"
          :loading-icon="$placeImg"
          :src="baseInfo.cover"
        >
        </van-image>
        <div class="detail">
          <p class="title">{{ baseInfo.name }}</p>
          <p class="number">有限期：1年</p>
          <p class="pay">￥{{ baseInfo.price_promote }}</p>
        </div>
      </div>
      <div class="orderBtn">
        <p>
          实付金额：<span>￥{{ baseInfo.price_promote }}</span>
        </p>
        <p @click="toBuy">确认支付</p>
      </div>
    </el-dialog>
    <el-dialog
      title="扫码支付"
      :visible.sync="dialogVisible"
      custom-class="payDialog"
      width="279px"
      @close="closeBuy"
    >
      <div class="toPay">
        <!-- <span>{{buyTime}}s后关闭</span> -->
        <span>暂时只支持「微信」扫码</span>
        <vue-qr :text="payUrl" width="200" height="200" :margin="0"></vue-qr>
        <!-- <p @click="closeBuy">已完成付款</p> -->
      </div>
    </el-dialog>
    <div class="content">
      <div class="banner" ref="banner">
        <img :src="baseInfo.cover" />
        <div class="vipMain" id="vipMain">
          <p v-if="!baseInfo.is_buy">开通{{ partalName }}VIP会员，畅享精品课程</p>
          <p v-else>尊敬的会员用户，VIP会员有效期到{{ baseInfo.expired_at }}</p>
          <div class="price" v-if="!baseInfo.is_buy">
            <p class="pricePay">现价</p>
            <p class="pricePay">￥</p>
            <p class="pricePay">{{ baseInfo.price_promote }}</p>
            <span>原价￥{{ baseInfo.price }}</span>
          </div>
          <img
          :src="!baseInfo.is_buy ? payBtn : payBtn"
          @click="toPay()"
        />
          <!-- <button @click="toPay()">立即购买</button> -->
        </div>
      </div>
      <div class="left">
        <div class="detailContent">
          <!-- 课程介绍 -->
          <div class="html" v-html="baseInfo.content_app" v-show="activeTab == 1"></div>
        </div>
      </div>

      <!-- <div class="right">
        <right :list="list" :books="books"></right>
      </div> -->
    </div>
  </div>
</template>

<script>
import VueQr from "vue-qr";
import certSave from "./CertSave.vue";
import right from "@/components/DetailRight.vue";
import lessonList from "@/components/DetailLessonList.vue";
import exampleList from "@/components/DetailExampleList.vue";
export default {
  components: { certSave, VueQr, right, lessonList, exampleList },
  name: "detail",
  data() {
    return {
      payBtn:'https://pkusaas.oss-cn-beijing.aliyuncs.com/miniprogram/study_center/tuoyu2/vip_btn.jpg',
      payBtn2:'https://pkusaas.oss-cn-beijing.aliyuncs.com/miniprogram/study_center/tuoyu2/vip_btn2.png',
      pass:
        "https://pkusaas.oss-cn-beijing.aliyuncs.com/miniprogram/study_center/tuoyu2/pass.jpg",
      noPass:
        "https://pkusaas.oss-cn-beijing.aliyuncs.com/miniprogram/study_center/tuoyu2/noPass.jpg",
      ifVIPDetail: true,
      typeList: [
        {
          name: "课程介绍",
          id: 1,
        },
        {
          name: "课程列表",
          id: 2,
        },
        {
          name: "课程讲义",
          id: 3,
        },
        {
          name: "思维导图",
          id: 4,
        },
        {
          name: "示范课程",
          id: 5,
        },
        {
          name: "课程考试",
          id: 6,
        },
        {
          name: "纸质书籍",
          id: 7,
        },
      ],
      baseInfo: {},
      payUrl: "",
      orderShow: false,
      dialogVisible: false,
      pageLoading: true,
      buyTimer: null,
      buyTime: 300,
      menu: 2,
      id: 0,
      lesson: "",
      list: [],
      activeTab: 1,
      textContent: "",
      color: ["#666", "#FF4D41", "#6DD400"],
      payTimer: null,
      isPay: false,
      testInfo: {},
    };
  },
  mounted() {
    this.$store.commit("VIPShow", false);
    // this.activeTab = this.$route.query.type=='video' ? 2 : 1
    this.$router.onReady(() => {
      if (this.$route.query.id) {
        this.id = this.$route.query.id;
        this.activeTab = this.$route.query.activeTab || 1;
        this.getData();
      }
      window.addEventListener("scroll", this.handleScrollToc, true);
    });
  },
  methods: {
    handleScrollToc() {
      let info = document.getElementById("vipMain").getBoundingClientRect();
      if(info.top>0){
        this.$store.commit("VIPShow", false);
      }else{
        this.$store.commit("VIPShow", true);
      }
    },
    getTag(id) {
      this.activeTab = id;
    },
    async toBuy() {
      this.orderShow = false;

      var payPrice = Number(this.baseInfo.price_promote);

      this.$toast.loading({
        message: "加载中...",
        forbidClick: true,
      });
      let { is_admin, mobile } = this.$store.state.user;
      // let { openid } = this.$store.state.wx;
      // if (mobile == "17600789950" || mobile == "17600789951" || mobile == '18666666666') {
      //   payPrice = 0.01;
      // }
      let res = await this.$api({
        url: "/api/order/make",
        method: "POST",
        data: {
          amount: payPrice,
          goods_sku_id: this.baseInfo.goods_sku_id,

          // amount:0.01,
          // goods_sku_id:2507,

          pay_type: 12,
          amount_coin: 0,
          amount_diamond: 0,
          amount_award: 0,
        },
      });
      if (res.code == 0) {
        this.$toast.clear();
        // 限免商品
        if (this.baseInfo.is_free == 2) {
          this.$toast("购买成功");
          if (this.ifVIPDetail) {
            this.$store.dispatch("getVIPInfo");
          }
        } else {
          this.dialogVisible = true;
          this.payUrl = JSON.parse(res.data.pay).code_url;
          this.isPay = true;
          this.payTimer = setTimeout(() => {
            this.getData();
          }, 1000);
        }
      } else {
        this.$toast(res.msg);
      }
      //  this.buyTime = 300
      //  this.buyTimer = setInterval(()=>{
      //    this.buyTime--
      //    if(this.buyTime==0){
      //     clearInterval(this.buyTimer)
      //     this.dialogVisible = false
      //    }
      //  },1000)
    },
    jumpBook(item) {
      window.open(item.url);
    },
    toExamin() {
      if (!this.baseInfo.can_exam) {
        this.$toast("您的课程还没有全部学习完成，请学习完全部课程后在参加考试");
        return;
      }
      if (this.baseInfo.can_exam) {
        this.$router.push({
          path: "./examinList",
          query: {
            id: this.baseInfo.test_id,
            isExamin: true,
          },
        });
      } else {
        this.$toast("您还未满足考试要求");
      }
    },
    closeBuy() {},
    async getData() {
      
      if (!this.isPay) this.pageLoading = true;
      let res = await this.$request.tuoyuDetail({ goods_id: this.id });

      this.pageLoading = false;
      if (res.code == 0) {
       
        this.baseInfo = res.data.goods_info; //课程介绍
        this.baseInfo.is_buy = res.data.is_buy;
        this.baseInfo.can_exam = res.data.can_exam;
        this.baseInfo.exam_pass = res.data.exam_pass;
        this.baseInfo.expired_at = res.data.expired_at
        this.baseInfo.test_id = res.data.base.test_id;
        if (this.isPay) {
          if (!res.data.is_buy) {
            this.payTimer = setTimeout(() => {
              this.getData();
            }, 1000);
          } else {
            this.isPay = false;
            this.dialogVisible = false;
            let vipInfo = {
              end_time: this.baseInfo.expired_at,
              goods_id: this.id,
              is_buy: this.baseInfo.is_buy,
              price: this.baseInfo.price,
              price_promote: this.baseInfo.price_promote,
            }
            this.$store.commit("SET_VIP", vipInfo);
          }
        }
      } else {
        this.$toast(res.msg);
      }
      // let res2 = await this.$request.tuoyuDetailBooks({
      //   goods_category_id: this.baseInfo.category_id,
      // });
      // if (res2.code == 0) {
      //   this.books = res2.data;
      // } else {
      //   this.$toast(res.msg);
      // }
    },
    async getVIPData() {
      if (!this.isPay) this.pageLoading = true;
      let res = await this.$api({
        method: "get",
        url: "/api/platform/goods/detail",
        data: {
          goods_id: this.id,
          plat: this.plat,
        },
      });
      this.pageLoading = false;
      if (res.code == 0) {
        console.log(res.data);
        alert('11111')
        this.baseInfo = res.data.goods_arr[0];
        this.baseInfo.is_buy = res.data.is_buy;
        // this.baseInfo.is_vip = res.data.is_vip;
        this.baseInfo.expired_at = res.data.expired_at
        this.plat_vip = res.data.plat_vip;
        console.log(this.baseInfo);
        this.ifVIPDetail = res.data.goods_arr[0].category_id == 102;
        this.typeList = this.typeList.slice(0, 1);
        this.$store.commit("SET_VIP", this.plat_vip);
        // this.activeTab = this.ifVIPDetail ? 0 : 1
        // console.log(this.typeList)
        if (this.isPay) {
          if (!res.data.is_buy) {
            this.payTimer = setTimeout(() => {
              this.getData();
            }, 1000);
          } else {
            this.isPay = false;
            this.dialogVisible = false;
          }
        }
      } else {
        this.$toast(res.msg);
      }
    },
    flatArr(arr, idArr) {
      let myArr = [];

      arr.forEach((item) => {
        if (item.lesson && item.lesson.length) {
          console.log("lesson");
          let arr = [
            { label: "lessonId", value: item.id },
            { label: "myType", value: "chapter" },
          ];
          console.log(idArr);
          myArr.push(...this.flatArr(item.lesson, [...idArr, ...arr]));
        } else if (item.speak && item.speak.length) {
          console.log("speak");
          let arr = [
            { label: "chapterId", value: item.id },
            { label: "myType", value: "speak" },
          ];
          myArr.push(...this.flatArr(item.speak, [...idArr, ...arr]));
        } else {
          console.log("none");
          idArr.forEach((v) => {
            item[v.label] = v.value;
          });
          myArr.push(item);
        }
      });
      return myArr;
    },
    toPay() {
      if (localStorage.getItem("user")) {
        this.orderShow = true;
      } else {
        this.$toast("请登录后购买");
      }
    },
    play(item) {
      if (!item.resource.length) {
      }
      let { ali_media_id, ccid, url, id } = item.resource[0];
      let name = item.lesson_name;
      console.log(ali_media_id, ccid);
      let videoId = ali_media_id;
      // if(item.is_buy != 1 && item.is_show != 1){ //既没有购买，也不是试看视频
      //     this.$toast('请购买后开始学习')
      //     return
      // }
      this.$store.commit("SET_VIDEO", item);
      console.log(url);
      let type = "ali";
      if (ccid && ccid != "undefined") {
        type = "cc";
        videoId = ccid;
      } else if (url) {
        type = "url";
      } else if (!ali_media_id) {
        console.log("none");
        this.$toast("当前视频未上传");
        return;
      }
      console.log("**");
      this.$router.push(
        `./player2?goods_id=${this.id}&lessonId=${item.lesson_id}&from=课程`
      );
    },

    jumpDetail(item) {
      this.$router.push(`./detail?id=${item.id}`);
    },
    toPage(url) {
      this.$router.push(url);
    },
  },
  computed: {
    partalName() {
      return this.$store.state.plat.name;
    },
    plat() {
      return this.$store.state.plat_id;
    },
    userName() {
      return JSON.parse(localStorage.getItem("user")).nickname;
    },
  },
  beforeRouteUpdate(to, from, next) {
    console.log("beforeRouteUpdate");
    console.log(to);
    this.id = to.query.id;
    this.activeTab = this.$route.query.type == "video" ? 2 : 1;
    this.getData();
    next();
  },
  beforeDestroy() {
    this.$store.commit("VIPShow", true);
    clearInterval(this.payTimer);
  },
};
</script>
<style>
body {
  background: #fff;
}

</style>
<style lang="scss" scoped>
:deep .el-dialog {
  margin-top: 0 !important;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
:deep .orderDialog {
  overflow: hidden;
  border-radius: 20px;
  .el-dialog__header {
    padding: 0 20px;
    height: 54px;
    display: flex;
    align-items: center;

    .el-dialog__title {
      font-size: 22px;
      color: #333333;
    }
  }
  .el-dialog__body {
    padding: 0;
  }
}
.banner {
  width: 100vw;
  min-width: 1200px;
  position: relative;
  > img {
    display: block;
    margin: 0 auto;
    width: auto;
    max-width: 100%;
  }
  .vipMain {
    background: url("https://pkusaas.oss-cn-beijing.aliyuncs.com/miniprogram/study_center/tuoyu2/vip_bg.jpg")
      center no-repeat;
    position: absolute;
    bottom: -32px;
    left: 50%;
    transform: translateX(-50%);
    height: 64px;
    width: 1200px;
    display: flex;
    align-items: center;
    text-align: left;
    padding: 0 20px;
    box-sizing: border-box;
    border-radius: 8px;
    > p {
      flex: 1;
      color: #222222;
      font-size: 24px;
      text-align: left;
    }
    .price {
      display: flex;
      align-items: center;
      padding-right: 56px;
      p {
        font-weight: bold;

        &:first-of-type {
          font-size: 23px;
          font-family: Source Han Sans CN;
          font-weight: 500;
          color: #201a10;
          // line-height: 20px;
          // text-shadow: 2px 0px 0px #921C11;

          background: linear-gradient(-42deg, #201a10 0%, #615444 35%, #262316 84%);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          filter: drop-shadow(2px 0px 0px #fff8f1);
        }
        &:nth-of-type(2) {
          font-size: 14px;
          font-family: Source Han Sans CN;
          color: #efcf9c;
          line-height: 28px;
          // filter: 0drop-shadow(2px 0px 0px #921C11);
          align-self: start;
          padding-left: 8px;
          background: linear-gradient(-42deg, #201a10 0%, #615444 35%, #262316 84%);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }
        &:nth-of-type(3) {
          font-size: 40px;
          color: #fcebde;

          background: linear-gradient(-42deg, #e94a30 0%, #ff5943 55%, #e15038 100%);
          filter: drop-shadow(2px 0px 0px #fff8f1);

          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }
      }
      span {
        font-size: 22px;
        font-weight: 400;
        text-decoration: line-through;
        color: #6d6652;
        display: block;
        padding-left: 25px;
        display: none;
      }
    }
    button {
      cursor: pointer;
      width: 108px;
      height: 42px;
      text-align: center;
      line-height: 42px;
      border-radius: 8px;
      color: #fff;
      font-size: 16px;
      border: none;
      background: #0080d6;
    }
  }
}
.coverImg {
  img {
    display: block;
    max-width: 100%;
    width: auto;
    margin: 0 auto;
  }
  .btn {
    margin: 10px auto 20px;
    cursor: pointer;
    width: 208px;
    height: 42px;
    text-align: center;
    line-height: 42px;
    border-radius: 8px;
    color: #fff;
    font-size: 16px;
    background: #0080d6;
  }
}

.opacity {
  opacity: 0.5;
}
.orderShow {
  display: flex;
  padding: 0 20px;
  margin-bottom: 20px;
  .detail {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }
  .cover {
    width: 179px !important;
    height: 101px;
    margin-right: 20px;
    border-radius: 10px;
    overflow: hidden;
    flex-shrink: 0;
  }
  .title {
    font-size: 20px;
    color: #333;
    font-weight: bold;
    overflow: hidden;
    display: -webkit-box;
    /*弹性伸缩盒子*/
    -webkit-box-orient: vertical;
    /*子元素垂直排列*/
    -webkit-line-clamp: 2;
    /*可以显示的行数，超出部分用...表示*/
    text-overflow: ellipsis;
    /*（多行文本的情况下，用省略号“…”隐藏溢出范围的文本)*/
  }
  .number {
    font-size: 14px;
  }
  .pay {
    font-size: 20px;
    color: #0080d6;
  }
}
.orderBtn {
  width: 100%;
  height: 54px;
  display: flex;
  padding: 0 20px;
  align-items: center;
  justify-content: space-between;
  border-top: 1px dashed #f1f1f1;
  p {
    font-size: 14px;

    span {
      font-size: 20px;
      color: #0080d6;
    }
  }
  p:nth-child(2) {
    width: 116px;
    height: 36px;
    font-size: 16px;
    background: #0080d6;
    border-radius: 18px;
    text-align: center;
    line-height: 36px;
    color: #ffffff;
    cursor: pointer;
  }
}
:deep .payDialog {
  overflow: hidden;
  border-radius: 20px;
  text-align: center;
  .el-dialog__body {
    padding: 0;
  }
}
.toPay {
  padding-bottom: 30px;
  span {
    color: #666;
    display: block;
    padding-bottom: 20px;
  }
  img {
    width: 200px;
  }
  > p {
    cursor: pointer;
    border: 1px solid #e5e5e5;
    border-radius: 5px;
    padding: 10px 0;
    width: 200px;
    text-align: center;
    margin: 20px auto 0;
  }
}
.cert {
  min-height: calc(100vh - 230px);
  overflow: hidden;
  .article {
    width: 960px;
    background: #fff;
    min-height: 100vh;
    margin: 20px auto;
    padding: 30px;

    .art-title {
      font-size: 32px;
      margin-bottom: 20px;
    }

    .art-user {
      display: flex;
      align-items: center;
    }

    .user-msg {
      font-size: 16px;
      color: #333;
      margin-left: 10px;

      .sub {
        font-size: 12px;
        color: #666;
      }
    }

    .art-content {
      font-size: 17px;
      word-break: break-word;
      line-height: 1.6;
      margin-top: 16px;
      color: #121212;
      font-family: -apple-system, BlinkMacSystemFont, Helvetica Neue, PingFang SC,
        Microsoft YaHei, Source Han Sans SC, Noto Sans CJK SC, WenQuanYi Micro Hei,
        sans-serif;
    }
  }
}

.content {
  .left {
    flex: 1;
    border-radius: 12px;
    margin-top: 30px;

    .top {
      display: flex;
      background: #f9f9f9;
      padding: 20px 20px;
      position: relative;
      border-radius: 10px;
      .tip {
        position: absolute;
        top: 20px;
        left: 20px;
        z-index: 1;
        img {
          width: 50px;
          // height: 35px;
        }
      }
      .cover {
        width: 300px;
        flex: 0 0 300px;
        margin-right: 20px;
        border-radius: 10px;
        overflow: hidden;
      }

      .detail {
        flex: 0 1 auto;
        display: flex;
        align-items: flex-start;
        flex-direction: column;

        div {
          display: flex;
        }
        .title {
          color: #333;
          font-weight: 500;
          font-size: 26px;
          line-height: 36px;
        }

        .number {
          flex: 1;
          color: #666;
          font-size: 14px;
          margin-top: 5px;
        }
        .pay {
          background: rgb(164, 0, 1);
          color: #fff;
          padding: 0 20px;
          margin-top: 20px;
          height: 40px;
          line-height: 40px;
          border-radius: 20px;
          cursor: pointer;
          margin-right: 20px;
          font-size: 16px;
          color: #fff;
          &:first-child {
            background: linear-gradient(-180deg, #51b4f7 0%, #0181d6 100%);
          }
          &:nth-child(2) {
            color: #633b11;
            background: linear-gradient(-180deg, #f4cfa7 0%, #daa36c 100%);
          }
        }
      }
    }
    .type {
      display: flex;
      align-items: center;
      margin-top: 30px;
      margin-bottom: 20px;
      border-bottom: 1px solid #dddddd;
      .type-item {
        margin-right: 10px;
        color: #7f7f7f;
        font-size: 20px;
        padding-bottom: 10px;
        cursor: pointer;
        &:last-of-type {
          margin-right: 0;
        }
      }
      .active {
        background: none;
        color: #333;
        border-bottom: 4px solid #0080d6;
      }
    }
    .typeMore {
      justify-content: space-between;
    }
    .typeLess {
      justify-content: flex-start;
      .type-item {
        margin-right: 50px;
      }
    }
    .detailContent {
      background: #fff;
      border-radius: 10px;
      overflow: hidden;
    }

    .lesson-list {
    }

    .html {
      text-align: center;
      margin-top: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;

      img {
        display: block;
        width: 100%;
      }
    }
  }

  .right {
    width: 360px;
    overflow: hidden;
    margin-top: 30px;
  }
}
</style>

<style lang="scss"></style>
